import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScriptReloaderService {
	private _document = inject(DOCUMENT);
	private _currentCatId = 0;
	private _platformId = inject(PLATFORM_ID);

	reloadScript(
		category = 0,
		ids: string[] = ['snigel-script-1', 'snigel-script-2'],
	): void {
		if (category === this._currentCatId) return;
		this._currentCatId = category;

		this._updateSnigelPubConf(category);
		ids?.forEach((id) => {
			const script = this._document.getElementById(id);
			if (script) {
				script.remove();
			}
		});
		this._loadInlineScript(category);
		this._loadExternalScript();
	}

	private _updateSnigelPubConf(category: number) {
		if (isPlatformBrowser(this._platformId)) {
			const win = window as any;
			const targeting = win.snigelPubConf?.adengine?.targeting;
			if (targeting) {
				win.snigelPubConf.adengine.targeting.key1 = category;
			}
		}
	}

	private _loadExternalScript(
		id = 'snigel-script-2',
		url = 'https://cdn.snigelweb.com/adengine/universitycompare.com/loader.js',
	) {
		const script = this._document.createElement('script');
		script.id = id;
		script.src = url;
		script.async = true;
		script.setAttribute('data-cfasync', 'false');
		this._document.body.appendChild(script);
	}

	private _loadInlineScript(categoryId = 0, id = 'snigel-script-1') {
		const script = this._document.createElement('script');
		script.id = id;
		script.type = 'text/javascript';
		script.setAttribute('data-cfasync', 'false');
		script.textContent = this._generateScriptText(categoryId);
		this._document.body.appendChild(script);
	}

	private _generateScriptText(id: number) {
		return `
			window.snigelPubConf = {
				adengine: {
					activeAdUnits: [],
					targeting: {
						"key1": ${id}
					}
				},
			};
		`;
	}
}
